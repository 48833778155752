import "./components/_scaleRoot";
const isSquareClose = window.innerHeight / window.innerWidth > .6;
const isPortrait = window.innerHeight / window.innerWidth > 1;
var isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function () {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Desktop: function () {
    return navigator.userAgent.match(/Windows NT/i);
  },
  Windows: function () {
    return navigator.userAgent.match(/IEMobile/i);
  },
  any: function () {
    return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
  }
};

function scaleWeb() {
  if (isMobile.any() && !isPortrait) {
    $(".main-landing .inner").scaleRootIngame().scaleObject();
  } else {
    $(".main-landing .inner ").scaleRoot({
      scaleMode: viewportIsMobile($("body").outerWidth())
        ? "containWidth"
        : "cover",
    });
  }
}

$(document).ready(function () {
  scaleWeb();
  $(window).on('orientationchange', function (e) {
    location.reload();
  })

});

// $(".outer--popup-iframe .inner ").css({
//     height: (viewportIsMobile($("body").outerWidth())) ? 'containWidth' : 'cover'
// });
function viewportIsMobile(width) {
  return width < 1025;
}

import "./components/_lightbox";

import MobileDetect from "mobile-detect";

var md = new MobileDetect(window.navigator.userAgent);

if ($(".getLink").length > 0) {
  switch (md.os()) {
    case "AndroidOS":
      // $(".getLink").addClass("open-download-and");
      // $(".open-download-and").click(function (e) {
      //   e.preventDefault();

      //   $(".popup--download-android").addClass("active");
      // });
      // break;
      $(".getLink").attr("href", $(".getLink-and").attr("href"));
      break;
    case "iOS":
      $(".getLink").attr("href", $(".getLink-ios").attr("href"));
      break;
    default:
  }
}

let url = window.location.href;

if (url.includes("?")) {
  let iframe = $(".section--2 iframe");
  let currentSrc = iframe.attr("data-src");

  let urlparams = new URLSearchParams(window.location.search);
  urlparams.delete("fbclid");

  iframe.attr("data-src", currentSrc + "?" + urlparams);
}

import "./components/_control-side";
import "./components/_content-tab";
