var close = false;
$(".close-side").click(function (e) {
  e.preventDefault();
  if (!close) {
    $(this).addClass("toOpen");
    $(".aside-right,.rightbar").addClass("closeAsideright");
    close = true;
  } else {
    $(this).removeClass("toOpen");
    $(".aside-right,.rightbar").removeClass("closeAsideright");
    close = false;
  }
});

var closeNav = false;
$(".open-nav").click(function (e) {
  e.preventDefault();
  if (!closeNav) {
    $(this).addClass("toClose");
    $(".sub-nav-mb,.main-pc").addClass("Open");
    closeNav = true;
  } else {
    $(this).removeClass("toClose");
    $(".sub-nav-mb,.main-pc").removeClass("Open");
    closeNav = false;
  }
});
var showdown = false;
$(".btn-show-down").click(function (e) {
  e.preventDefault();
  if (!showdown) {
    $(this).addClass("active");
    $(".logout").addClass("active");
    showdown = true;
  } else {
    $(this).removeClass("active");
    $(".logout").removeClass("active");
    showdown = false;
  }
});

$(".btn-copy").click(function (e) {
  e.preventDefault();
  let target = $(this).data("target");
  let value = $(target).text();
  let copyText = document.createElement("input");
  copyText.value = value;
  copyText.style.cssText = "opacity:0;";
  document.body.appendChild(copyText);
  copyText.select();
  copyText.setSelectionRange(0, 99999);
  document.execCommand("copy", false);
  copyText.remove();
  if (typeof copySuccessText == "undefined")
    copySuccessText = "Bạn đã sao chép thành công";
  alert(`${copySuccessText} : ` + value, false);
});


