if ($(".popup__background").length > 0) {
  $(".popup__background").each(function () {
    $(this).on("click", function () {
      $(this).parent().removeClass("active");
    });
  });
}
$("body").on("click", ".close-popup, .btn-close", function (event) {
  //$(".popup").removeClass("active");
  // $(this).parent().parent().removeClass("active");
  $(this).parents('.popup').removeClass("active");
});

var target;
$(".btn-openpopup").on("click", function (e) {
  target = $(this).data("target");
  $(target).addClass("active");
  e.preventDefault();
});
