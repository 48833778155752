$(function () {
    $('.tab-wrapper').each(function (index, value) {
        $(this).children().closest('.tab').find('.tab-name').not('.deactive').first().trigger('click');
    });
});

function activeTab(obj) {
    $(obj).closest('.tab').find('li').removeClass('active');
    $(obj).addClass('active');
    var id = $(obj).find('a').attr('href');
    $(obj).closest('.tab-wrapper').find('.tab-item').hide();
    $(id).show();
    $(obj).closest('.tab-wrapper').find('.tab-wrapper').each(function (index, value) {
        $(this).children().closest('.tab').find('.tab-name').first().trigger('click');
    });
}

$('.tab li').click(function () {
    activeTab(this);
    return false;
});