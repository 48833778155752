import "lazysizes";
import Scrollbar from "smooth-scrollbar";
import BezierEasing from "bezier-easing";

// SCROLL
// -- Init
var scaleratio = $(window).outerHeight() / 1000;
var aniComplete = true;
if ($(".regular").hasClass("active")) {
  scrollbars["bxh1"] = Scrollbar.init($(".list-data-bxh")[0], {
    alwaysShowTracks: true,
  });
}
if ($(".top-ranking").hasClass("active")) {
  scrollbars["bxh1"] = Scrollbar.init($(".list-data-bxh")[1], {
    alwaysShowTracks: true,
  });
}

if ($(".scrollContent").length > 0) {
  let frameSwipe = {
    total: 2,
    frames: [
      ".section--1",
    ],
    navigation: ".nav-top li",
    index: 0,
    isEndAnimate: true,
    animateDelay: 400,
    animateEndDelay: 400,
  };

  $(".scrollLink").on("click", function (e) {
    e.preventDefault();
    if (frameSwipe.isEndAnimate) {
      frameSwipe.isEndAnimate = false;

      $("html").animate(
        {
          scrollTop: $($(this).data("scroll-link")).offset().top,
        },
        600,
        function () {
          frameSwipe.isEndAnimate = true;
        }
      );
    }
  });
}

if ($(".scrollArticle").length > 0) {
  for (var i = 0; i < $(".scrollArticle").length; i++) {
    scrollbars['article'] = Scrollbar.init($(".scrollArticle")[i], {
      alwaysShowTracks: true
    })
  }
}
$(function () {
  if ($(".scrollServer").length > 0) {
    Scrollbar.init($(".scrollServer")[0], {
      alwaysShowTracks: true
    })
  }
  if($('.btnCumMayChu').length > 0 ) {
    $('.btnCumMayChu').on('click', function(e){
      let activeList = $(this).data('list');
      $('.btnCumMayChu').removeClass('active')
      $(".listMayChu").removeClass('active')
      $(this).addClass('active');
      $(`#listMayChu${activeList}`).addClass('active');
      e.preventDefault();
    })
  }
})
$(document).ready(function () {
  var rewardResult;
  dndPromotion({
    url: urlPromotion,
    callback: function () {
      // if ($("#rewardResult").length > 0) {
      //   let swiperName = "rewardResult";
      //   rewardResult = new Swiper("#" + swiperName, {
      //     // spaceBetween: 32,
      //     slidesPerView: 1,
      //     centeredSlides: true,
      //     navigation: {
      //       prevEl: ".swiper-button-prev-rewardResult",
      //       nextEl: ".swiper-button-next-rewardResult",
      //     },
      //   });
      // }
    },
  });

  window["getAwardSrcById"] = getAwardSrcById;

});

import { dndPromotion, getAwardSrcById } from "./components/_mqs";
import Swiper from "Swiper";
import "swiper/css/swiper.min.css";


function viewportIsMobile(width) {
  return width < 1025;
}
